<template>
  <v-dialog v-model="dialog" max-width="640px">
    <v-card :loading="loading">
      <v-card-title class="d-flex justify-center py-6 mb-6 blue lighten-1 mc2i white--text">
        Ajouter un candidat
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-row wrap>
              <!--Trigramme -->
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete v-model="candidat.trigrammeTAC" :items="champs.TAC" label="TAC*"
                  :rules="[(v) => !!v || 'Le TAC est obligatoire']" outlined dense rounded
                  background-color="grey lighten-4" required></v-autocomplete>
              </v-col>
              <!--Prequa Tel -->
              <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'OK'">
                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="auto" rounded="xl" v-model="isPrequaTelOpen" ref="prequaMenu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="dateEnToFr(candidat.prequa_tel)" label="Préqua. Tel*"
                      prepend-icon="mdi-calendar" readonly clearable v-bind="attrs" v-on="on" :rules="[
                        (v) => !!v || 'La Préqua. tel est obligatoire',
                      ]" outlined dense rounded background-color="grey lighten-4" required></v-text-field>
                  </template>
                  <v-date-picker :first-day-of-week="1" v-model="candidat.prequa_tel" locale="fr-FR"
                    @input="closePrequaTelPicker"></v-date-picker>
                </v-menu>
              </v-col>
              <!--Nom -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="candidat.nom" background-color="grey lighten-4" dense rounded outlined label="Nom*"
                  :rules="[(v) => !!v || 'Le nom est obligatoire']" required></v-text-field>
              </v-col>
              <!--Prénom -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="candidat.prenom" background-color="grey lighten-4" dense rounded outlined
                  label="Prenom*" :rules="[(v) => !!v || 'Le prénom est obligatoire']" required></v-text-field>
              </v-col>
              <!--Province -->
              <v-col cols="12" sm="12" md="12">
                <v-select v-model="candidat.label_ville_province" :items="champs.label_ville_province" label="Province"
                  outlined dense rounded clearable background-color="grey lighten-4"></v-select>
              </v-col>

              <!--Transverse-->
              <v-col v-if="type_candidat == 'ope'">
                <v-checkbox v-model="candidat.transverse" label="Transverse"></v-checkbox>
              </v-col>
              <!-- is data -->
              <v-col v-if="type_candidat == 'ope'">
                <v-checkbox v-model="candidat.is_data" label="Data"></v-checkbox>
              </v-col>
              <!-- HP -->
              <v-col>
                <v-checkbox v-model="candidat.high_potential" label="HP"></v-checkbox>
              </v-col>
              <!-- mc2i Opp -->
              <v-col v-if="type_candidat == 'ope'">
                <v-checkbox v-model="candidat.mc2i_opportunities" label="mc2i opp"></v-checkbox>
              </v-col>
              <!--Cooptation-->
              <v-col>
                <v-checkbox v-model="candidat.cooptation" label="Cooptation"></v-checkbox>
              </v-col>
              <!--Lille-->
              <!-- <v-col v-if="type_candidat == 'ope'">
                <v-checkbox
                  v-model="candidat.is_lille"
                  label="Lille"
                ></v-checkbox>
              </v-col> -->
              <!--Remontée Linkedin-->
              <v-col>
                <v-checkbox v-model="candidat.is_linkedin" label="Remontée Linkedin"></v-checkbox>
              </v-col>
              <!--Disponibilite -->
              <!-- Disponibilite -->
              <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'OK' || candidat.statut == 'Embauché'">
                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="auto" rounded="xl" v-model="isDatePickerOpen">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="candidat.disponibilite" label="Disponibilité*" prepend-icon="mdi-calendar"
                      readonly clearable v-bind="attrs" v-on="on" :rules="[
                        (v) => !!v || 'La disponibilité est obligatoire',
                      ]" outlined dense rounded background-color="grey lighten-4" required></v-text-field>
                  </template>
                  <v-date-picker v-model="candidat.disponibilite" type="month" no-title scrollable locale="fr-FR"
                    @input="closeDatePicker"></v-date-picker>
                </v-menu>
              </v-col>

               <!--Date de Démarrage cible -->
               <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'ope'">
                  <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="auto" rounded="xl" v-model="isDateDemarageCible">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="candidat.date_demarrage_cible" label="Date démarrage cible*"
                        prepend-icon="mdi-calendar" readonly clearable clear-icon="mdi-close-circle" v-bind="attrs"
                        v-on="on" :rules="[
                          (v) => !!v || 'La date démarrage cible est obligatoire',
                        ]" outlined dense rounded background-color="grey lighten-4" required></v-text-field>
                    </template>
                    <v-date-picker v-model="candidat.date_demarrage_cible" type="month" locale="fr-FR" @input="closeDemarrageCiblePicker"></v-date-picker>
                  </v-menu>
                </v-col>
              <!--Profil -->
              <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'ope'">
                <v-autocomplete v-model="candidat.profil" :items="champs.profils" label="Emploi*"
                  :rules="[(v) => !!v || 'Emploi est obligatoire']" outlined dense rounded
                  background-color="grey lighten-4" required></v-autocomplete>
              </v-col>
              <!--Profil FCT -->
              <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'fct'">
                <v-autocomplete v-model="candidat.profilFCT" :items="champs.profilsFCT" label="Emploi*"
                  :rules="[(v) => !!v || 'Emploi est obligatoire']" outlined dense rounded
                  background-color="grey lighten-4" required></v-autocomplete>
              </v-col>
              <!--Type de contrat-->
              <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'fct'">
                <v-select v-model="candidat.label_contrat" :items="champs.label_contrat" label="Type de contrat*"
                  :rules="[(v) => !!v || 'Le type de contrat est obligatoire',]" required outlined dense rounded
                  background-color="grey lighten-4"></v-select>
              </v-col>
              <!--Entité-->
              <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'fct'">
                <v-select v-model="candidat.label_entite" :items="champs.label_entite" label="Entité" outlined dense
                  rounded background-color="grey lighten-4"></v-select>
              </v-col>

              <!--Sexe -->
              <v-col cols="12" sm="12" md="12">
                <v-select v-model="candidat.sexe" :items="champs.sexe" label="Sexe*"
                  :rules="[(v) => !!v || 'Le sexe est obligatoire']" required outlined dense rounded
                  background-color="grey lighten-4"></v-select>
              </v-col>

              <!--Ecole -->
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete v-model="candidat.nom_ecole" :items="champs.nom_ecole" label="Ecole*"
                  :rules="[(v) => !!v || 'L\'école est obligatoire']" required outlined dense rounded
                  background-color="grey lighten-4"></v-autocomplete>
              </v-col>

              <!--Année diplomation -->
              <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'ope' && candidat.statut == 'Embauché'">
                <v-text-field v-model="candidat.annee_diplomation" label="Année de diplomation*" type="number" :rules="[(v) => !!v || 'L\'année de diplomation est obligatoire']" required outlined dense rounded background-color="grey lighten-4" ></v-text-field>
              </v-col>

              <!--Expertise -->
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-if="type_candidat == 'ope'" v-model="candidat.expertise" label="Expertise" outlined dense
                  rounded background-color="grey lighten-4"></v-text-field>
              </v-col>
              <!--Souhait -->
              <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'ope'">
                <v-text-field v-model="candidat.souhait" label="Souhait*"
                  :rules="[(v) => !!v || 'Le souhait est obligatoire']" required outlined dense rounded
                  background-color="grey lighten-4"></v-text-field>
              </v-col>
              <!--Pole -->
              <v-col cols="12" sm="12" md="12">
                <v-select v-model="candidat.pole" :items="champs.poles" label="Pôle*"
                  :rules="[(v) => !!v || 'Le pôle est obligatoire']" required outlined dense rounded
                  background-color="grey lighten-4"></v-select>
              </v-col>
              <!--Source chasse -->
              <v-col cols="12" sm="12" md="12">
                <v-select v-model="candidat.source_chasse" :items="champs.sources_chasse" clearable
                  clear-icon="mdi-close-circle" label="Source Chasse" outlined dense rounded
                  background-color="grey lighten-4"></v-select>
              </v-col>
              <!--Expertises clés-->
              <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'ope'">
                <label>Expertises clés</label>
                <v-chip-group v-model="candidat.expertises_cles" column multiple>
                  <v-chip v-for="expertise_cle in champs.expertises_cles" :key="expertise_cle" :value="expertise_cle"
                    filter outlined>
                    {{ expertise_cle }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="6" sm="6" md="6"
                v-if="(candidat.statut == 'OK' || candidat.statut == 'Embauché' || candidat.statut == 'Désistement') && type_candidat == 'ope'">
                <v-select v-model="candidat.expertise_sectorielle" :items="champs.expertises_sectorielles"
                  label="Expertise sectorielle"></v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6"
                v-if="(candidat.statut == 'OK' || candidat.statut == 'Embauché' || candidat.statut == 'Désistement') && type_candidat == 'ope'">
                <v-select v-model="candidat.expertise_fct_tech" :items="champs.expertises_fct_tech"
                  label="Expertise fonctionnelle/technique"></v-select>
              </v-col>
              <!--Commentaire -->
              <v-col cols="12" sm="12" md="12">
                <v-textarea v-model="candidat.commentaire" auto-grow label="Commentaire" outlined dense rounded
                  background-color="grey lighten-4"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="blue" rounded text @click="addCandidat()" :disabled="!valid">Valider</v-btn>
        <v-btn color="red" rounded text @click="closeDialog()">Annuler</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addCandidats } from '../../api/candidats';
import { dateEnToFr, dateInText } from '../../commonFunction/date';
import { eventBus } from '../../api/eventBus'
import { customYears } from "../../commonFunction/date";

export default {
  data: () => ({
    dialog: false,
    type_candidat: "",
    valid: false,
    loading: false,
    isDatePickerOpen: false,
    isDateDemarageCible: false,
    isPrequaTelOpen: false,
    customYears: [],
    // Candidat avec tous les champs relatifs à un candidat OK
    candidat: {
      trigrammeTAC: null,
      nom: null,
      prenom: null,
      disponibilite: null,
      profil: null,
      nom_ecole: null,
      type_ecole: null,
      transverse: null,
      cooptation: null,
      is_lille: null,
      province: null,
      sexe: null,
      rang_ecole: null,
      statut: "OK",
      prequa_tel: null,
      expertise: null,
      souhait: null,
      pole: null,
      source_chasse: null,
      // expertises_cles: null,
      commentaire: null,
      annee_diplomation: null,
      // champs spécifiques aux FCT
      profilFCT: null,
      label_contrat: null,
      label_entite: null,
      expertises_cles: [],
      date_demarrage_cible: null
    }
  }),
  async mounted() {
    this.customYears = customYears()
  },
  methods: {
    openDialog(typeCandidat) {
      // Ouverture du dialogue d'ajout et récupération du type de candidat à ajouter
      this.type_candidat = typeCandidat;
      this.dialog = true;
    },
    closeDatePicker() {
      this.isDatePickerOpen = false; // Ferme uniquement le menu de calendrier
    },
    closeDemarrageCiblePicker(){
      this.isDateDemarageCible = false;
    },
    closePrequaTelPicker() {
      this.isPrequaTelOpen = false;
    },
    async addCandidat() {
      this.loading = true;
      // On met la date de disponibilité au bon format YYYY-MM-DD pour l'API
      this.candidat.disponibilite += "-01"
      if(this.type_candidat == 'ope'){
        this.candidat.date_demarrage_cible += '-01'
      }

      // On ajoute le candidat via l'API
      await addCandidats(this.type_candidat, this.candidat)
        //console.log("Avant l'envoie ");
        this.$emit('candidatAdded');
        eventBus.$emit('candidatAdded')
        //console.log(" evenement candidatAdded envoyé");
        this.candidat.prequa_tel = null
        this.candidat.expertises_cles = []
        this.loading = false
        this.closeDialog()
    },
    //Fermeture du dialogue d'ajout et réinitialisation du formulaire
    closeDialog() {
      this.dialog = false
      this.$refs.form.reset();
    },
    dateEnToFr, dateInText
  },
  computed: {
    // Récupération des champs nécessaires au remplisssage du formulaire
    champs() {
      return this.$store.getters.champs
    }
  },
  
};
</script>